import { appSettings } from '@/AppSettings';
import { XHR } from '@/helpers/xhr';
import { storage } from '@/helpers/storage';

export function mediaUrl(path) {
  let url = `${appSettings.get('mediaUrl')}${path}`;
  if (!url.startsWith('http')) url = `${window.location.origin}${url}`;
  return url;
}

export function imageUrl(path, params = {}) {
  if (!params.width && !params.height) {
    return mediaUrl(path);
  }

  let query = [`url=${mediaUrl(path)}`, `fit=${params.fit || 'cover'}`];

  if (params.width) {
    query.push(`w=${params.width}`);
  }

  if (params.height) {
    query.push(`h=${params.height}`);
  }

  // Make 'url' parameter the last one. Otherwise Ant uploader won't show preview image
  query = `?${query.reverse().join('&')}`;
  return `${appSettings.get('resizerUrl')}${query}`;
}

export async function uploadFile(file) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    const token = storage.get('authToken');

    XHR.post(appSettings.get('uploaderUrl'), {
      data: formData,
      dataType: 'formdata',
      absoluteUrl: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(resolve, (error) => {
      reject(new Error(error));
    });
  });
}
